import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import Seo from '~components/Seo'
import Section from '~components/Section'
import BouncingBall from '~components/BouncingBall'
import { useRef } from 'react'
import useSiteSettings from '~utils/useSiteSettings'
import styled from '@emotion/styled'
import { useSiteState } from '~context/siteContext'

const Error404 = () => {

	const { fourOFourBallColours, fourOFourBallText } = useSiteSettings()
	const pageRef = useRef()

	const [loaded, setLoaded] = useState()

	const [siteState, setSiteState] = useSiteState()

	useEffect(() => {
		if(pageRef.current){
			setLoaded(true)
		}
	}, [pageRef.current])

	useEffect(() => {
		setTimeout(()=> {
			setSiteState(prevState => ({
				...prevState,
				hideBall: true
			}))
		}, 100)
	}, [])

	return (
		<>
			<Seo
				title="404"
			/>
			<Wrap ref={pageRef}>
				<Section>
					{loaded && 
						<BouncingBall text={fourOFourBallText} colours={fourOFourBallColours} boundingBox={pageRef.current} large={true}/>
					}
				</Section>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	flex: 1;
	height: 100%;
`

export default Error404
